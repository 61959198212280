import '../App.css';

function Divider() {
  return (
    <div style={{display:"flex",width:"100%", justifyContent:"center"}}>
        <div className="divider"/>
    </div>
  );
}

export default Divider;